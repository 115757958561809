import { useEffect, useRef } from "react";
import crypto from "crypto-js";
import Axios from "axios";
import { toast } from "react-toastify";

// Function to load script and append in DOM tree.
const loadScript = (src) =>
  new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      console.log("razorpay loaded successfully");
      resolve(true);
    };
    script.onerror = () => {
      console.log("error in loading razorpay");
      resolve(false);
    };
    document.body.appendChild(script);
  });

const RenderRazorpay = ({ orderId, amount, submitButton }) => {
  const paymentId = useRef(null);
  const paymentMethod = useRef(null);

  // To load razorpay checkout modal script.
  const displayRazorpay = async (options) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      console.log("Razorpay SDK failed to load. Are you online?");
      return;
    }
    // All information is loaded in options which we will discuss later.
    const rzp1 = new window.Razorpay(options);

    // If you want to retreive the chosen payment method.
    rzp1.on("payment.submit", (response) => {
      // paymentMethod.current = response.method;
      console.log("response ", response);
    });

    // To get payment id in case of failed transaction.
    rzp1.on("payment.failed", (response) => {
      paymentId.current = response.error.metadata.payment_id;
    });

    // to open razorpay checkout modal.
    rzp1.open();
  };

  // informing server about payment
  const handlePayment = async (status, orderDetails = {}) => {
    await Axios.post(`${serverBaseUrl}/payment`, {
      status,
      orderDetails,
    });
  };

  // we will be filling this object in next step.
  const options = {
    key: "rzp_test_gpnYFxJ6clojU4", // key id from props
    amount,
    currency: "INR",
    name: "Lemon Foundation", // Title for your organization to display in checkout modal
    // image, // custom logo  url
    order_id: orderId, // order id from props
    // This handler menthod is always executed in case of succeeded payment
    handler: (response) => {
      console.log("succeeded");
      console.log(response);
      paymentId.current = response.razorpay_payment_id;

      // Most important step to capture and authorize the payment. This can be done of Backend server.
      const keySecret = "YRgwLVFYl5lMG3MD6q1a3i8R";
      const succeeded =
        crypto
          .HmacSHA256(`${orderId}|${response.razorpay_payment_id}`, keySecret)
          .toString() === response.razorpay_signature;

      // If successfully authorized. Then we can consider the payment as successful.
      if (succeeded) {
        submitButton();
        toast.success("Donation payment is Successfull.");
        // window.location.reload();
      } else {
        toast.error("Donation payment is failed.");
      }
    },
  };

  useEffect(() => {
    console.log("in razorpay");
    displayRazorpay(options);
  }, []);

  return null;
};

export default RenderRazorpay;
